import React, { useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import { groupBy, prop } from 'ramda'

import Switch from '../components/shared/Switch'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {
  Body,
  Caption,
  Header,
  SpecialHeader2,
} from '../components/shared/Text'
import { messages } from '../hooks/useMessages'
import Markdown from '../components/shared/Markdown'
import {
  DEFAULT_CONSENT,
  getCookie,
  removeCookie,
  setCookie,
} from '../utils/cookies'
import useSetInterval from '../hooks/useSetInterval'

const Paragraph = ({ title, desc }) => (
  <div className="mx-auto flex flex-col gap-4">
    <Header className="capitalize">{title}</Header>
    <Body className="self-center xl:max-w-[30vw]">
      <Markdown>{desc}</Markdown>
    </Body>
  </div>
)

const CookiesList = ({ type, cookies }) => (
  // cookies = [{
  //   id,
  //   name,
  //   type,
  //   description,
  //   consent
  // }]
  <div key={type} className="mx-auto flex flex-col gap-4">
    <Header>{type}</Header>
    <Body className="self-center xl:max-w-[50vw]">
      <div className="flex flex-col rounded-md bg-[#191919] p-6">
        {cookies.map(({ id, name, description, consent }) => (
          <div
            key={id}
            className="flex flex-col gap-7 leading-5 lg:flex-row lg:items-center"
          >
            <Caption className="font-semibold italic lg:min-w-[10rem]">
              {name}
            </Caption>
            <div>
              <Markdown className="text-[.9rem]">{description}</Markdown>
            </div>
            <div>{consent ? <CookieConsent consent={consent} /> : null}</div>
          </div>
        ))}
      </div>
    </Body>
  </div>
)

function CookieConsent({ consent }) {
  const [allow, setAllow] = useState(!!getCookie(consent))
  const onChange = useCallback(
    isChecked => {
      if (isChecked) {
        setCookie({ cookieName: consent, useSharedDomain: true, value: true })
      } else {
        removeCookie({ cookieName: consent, useSharedDomain: true })
      }
      setAllow(isChecked)
      // we always update the default consent cookie to state the user made a (new) choice
      setCookie({ cookieName: DEFAULT_CONSENT, useSharedDomain: true })
    },
    [setAllow, allow, consent]
  )
  useSetInterval(
    () => {
      // when the cookie banner is showing, poll to update the switch
      // in case the user clicked accept all
      if (allow !== !!getCookie(consent)) {
        setAllow(true)
      }
    },
    500,
    !getCookie(DEFAULT_CONSENT)
  )
  return <Switch checked={allow || false} onChange={onChange} />
}

export default function CookiesPolicy({ pageContext, data }) {
  const { strapiCookiePolicy } = data

  const {
    title,
    whatAreCookiesTitle,
    whatAreCookiesDesc: {
      data: { whatAreCookiesDesc },
    },
    howWeUseCookiesTitle,
    howWeUseCookiesDesc: {
      data: { howWeUseCookiesDesc },
    },
    cookies,
  } = strapiCookiePolicy

  const cookiesByType = groupBy(prop('type'), cookies)
  const cookieTypes = Object.keys(cookiesByType).sort()

  const seo = {
    metaTitle: title,
    // TODO: how to get just the text without markdown symbols?
    metaDescription: howWeUseCookiesDesc,
  }

  return (
    <Layout pageContext={pageContext} onlyFooter>
      <Seo seo={seo} />
      <div className="container pt-[10vw]">
        <div className="flex flex-col justify-center gap-14 text-center lg:px-16">
          <SpecialHeader2 className="pt-10 text-center">{title}</SpecialHeader2>

          <Paragraph title={whatAreCookiesTitle} desc={whatAreCookiesDesc} />
          <Paragraph title={howWeUseCookiesTitle} desc={howWeUseCookiesDesc} />

          {cookieTypes.map(type => (
            <CookiesList key={type} type={type} cookies={cookiesByType[type]} />
          ))}

          <Body className="text-center underline">
            <button type="button" onClick={() => window.history.back()}>
              {messages(pageContext.locale)('back')}
            </button>
          </Body>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($locale: String) {
    strapiCookiePolicy(locale: { eq: $locale }) {
      title
      whatAreCookiesTitle
      whatAreCookiesDesc {
        data {
          whatAreCookiesDesc
        }
      }
      howWeUseCookiesTitle
      howWeUseCookiesDesc {
        data {
          howWeUseCookiesDesc
        }
      }
      cookiesFootnote {
        data {
          cookiesFootnote
        }
      }
      cookies {
        id
        name
        type
        description
        consent
      }
    }
  }
`
