import React from 'react'
import ReactSwitch from 'react-switch'

export default function Switch(props) {
  return (
    <ReactSwitch
      activeBoxShadow="0 0 2px 3px #161616"
      onColor="#FFA800"
      {...props}
    />
  )
}
